(function () {
  document.addEventListener('DOMContentLoaded', function () {
    /**
     * Our objects
     */

    const filtersDialog = document.querySelector("#aos-filters-dialog");
    const filterOpener = document.querySelector("#open-aos-filters");
    const filterHider = document.querySelector("#aos-filters__closer");

    const aosNameFilter = document.querySelector("#aos-name-filter");
    const aosSchoolFilter = document.querySelector("#aos-school-filter");
    const aosResetter = document.querySelector("#aos-filters__resetter");
    const aosViewer = document.querySelector("#aos-filters__viewer");

    const theBigRow = document.querySelector(".aos-listing-row");
    const endBox = document.querySelector('.end-of-results-box');
    const startBox = document.querySelector('.start-of-results-box');

    const aosCards = theBigRow.querySelectorAll('[data-aos-card]');

    /**
     * Dialog shower/hiders
     */

    filterOpener.addEventListener("click", () => {
      filtersDialog.showModal();
      updateDialogPosition();
    });

    filterHider.addEventListener("click", hideDialog);

    // Basic dismiss - clicking the backdrop will dismiss the dialog
    filtersDialog.addEventListener("click", function (e) {
      if (e.target.nodeName === "DIALOG") {
        filterHider.click();
      }
    });

    /**
     * The filters
     */

    aosNameFilter.addEventListener("input", runUpdates);
    aosSchoolFilter.addEventListener("input", runUpdates);

    aosResetter.addEventListener("click", (e) => {
      e.preventDefault();

      aosNameFilter.value = "";
      aosSchoolFilter.value = "either";

      aosCards.forEach((card) => {
        card.removeAttribute("data-school-filter");
        card.removeAttribute("data-name-filter");
      });

      inertCards();
      updateFilterButton();
    });

    aosViewer.addEventListener("click", (e) => {
      e.preventDefault();
      filterHider.click();
    });

    /**
     * Scroll action.
     */

    // Just start off assuming it is scrolledLeft...
    theBigRow.classList.add("scrolledLeft");

    let lastKnownScrollPosition = 0;
    let ticking = false;

    theBigRow.addEventListener("scroll", () => {
      lastKnownScrollPosition = theBigRow.scrollLeft;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          aosScrollUpdates(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });

    /**
     * Helper functions
     */

    function updateDialogPosition() {
      let boxRect = filtersDialog.getBoundingClientRect();
      let buttonPosition = filterOpener.getBoundingClientRect();
      let documentStyle = document.documentElement.style;

      if (buttonPosition.top > boxRect.height) {
        filtersDialog.setAttribute("data-position", "top");
        documentStyle.setProperty("--down", `${buttonPosition.top}px`);
        documentStyle.setProperty("--left", `${buttonPosition.left}px`);
      } else {
        filtersDialog.setAttribute("data-position", "bottom");
        documentStyle.setProperty("--down", `${buttonPosition.bottom}px`);
        documentStyle.setProperty("--left", `${buttonPosition.left}px`);
      }
    }

    function hideDialog(e) {
      e.preventDefault();
      filtersDialog.classList.add("is-hidden");
      filtersDialog.addEventListener(
        "animationend",
        function () {
          filtersDialog.classList.remove("is-hidden");
          filtersDialog.close();

          if (filtersDialog.getAttribute("data-filter-active") && startBox.getBoundingClientRect().left > 0) {
            theBigRow.scrollBy({
              top: 0,
              left: startBox.getBoundingClientRect().width,
              behavior: 'smooth'
            })
          }
        },
        {
          once: true,
        },
        false
      );
    }

    function inertCards() {
      aosCards.forEach((card) => {
        if (card.hasAttribute('data-name-filter') || card.hasAttribute('data-school-filter')) {
          card.setAttribute('inert', 'true');
        } else {
          card.removeAttribute('inert');
        }
      })
    };

    function aosScrollUpdates(scrollPos) {
      if (!theBigRow.classList.contains("scrolledRight") && scrollPos >= 50) {
        theBigRow.classList.add("scrolledRight");
      }

      if (theBigRow.classList.contains("scrolledRight")) {
        if (scrollPos >= 55) {
          theBigRow.classList.add("scrolledRight100");
        } else {
          theBigRow.classList.remove("scrolledRight100");
        }
      }

      if (theBigRow.classList.contains("scrolledRight") && scrollPos < 50) {
        theBigRow.classList.remove("scrolledRight");
      }

      // Hide the end-of-row fader.
      let results = endBox.getBoundingClientRect();
      if (results.right < window.innerWidth) {
        theBigRow.classList.remove("scrolledLeft");
      } else {
        theBigRow.classList.add("scrolledLeft");
      }
    }

    function updateFilterButton() {
      let numberOfInertCards = theBigRow.querySelectorAll('[data-aos-card][inert]').length;
      if (numberOfInertCards > 0) {
        filtersDialog.setAttribute("data-filter-active", "true")
      } else {
        filtersDialog.removeAttribute("data-filter-active");
      }
    }

    function runUpdates(e) {
      aosCards.forEach(updateFilters, e.currentTarget);

      inertCards();
      updateFilterButton();
    }

    function updateFilters(card) {
      let curVal = this.value.toLowerCase();

      switch (this.id) {
        case 'aos-name-filter':
          let curName = card.querySelector(".card__content__title").innerText.toLowerCase();
          let curTerms = card.getAttribute('data-search-terms') || '';

          if (curName.indexOf(curVal) < 0 && curTerms.indexOf(curVal) < 0) {
            card.setAttribute("data-name-filter", "false");
          } else {
            card.removeAttribute("data-name-filter");
          }

          break;
        case 'aos-school-filter':
          let curSchool = card.getAttribute("data-school");

          if (curVal === "either" || curSchool.indexOf(curVal) >= 0) {
            card.removeAttribute("data-school-filter");
          } else {
            card.setAttribute("data-school-filter", "false");
          }

          break;
      }
    }
  });
})();
